<template>
  <div>
    <div class="row">
      <b-modal
        id="clientModal"
        ref="clientModal"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <transition name="fade" mode="out-in">
          <div v-if="clientState === 'showClientSelect'" key="cl">
            <div id="searchClient">
              <b-form-input
                v-model="search"
                @keyup.enter="searchClient()"
                class="text-center"
                placeholder="Name/Zimmer"
                autocomplete="off"
              ></b-form-input>
              <button
                class="btn btn-md btn-secondary mx-0 btn-block"
                @click="searchClient()"
              >
                {{ "Suchen" | translate }}
              </button>

              <div
                class="p-2"
                style="
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  min-height: 166px;
                "
              >
                <div v-if="searching" id="pwloader"></div>
                <div v-if="searchError">
                  {{"Keine Treffer" | translate}}
                </div>

                <div class="row m-0 p-0">
                  <div
                    class="col-6 px-1"
                    v-for="(item, index) in clientList"
                    :key="index"
                  >
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="upCid(item)"
                    >
                      {{ shorten(item.name) }} -
                      {{ check(item.room) }}<span v-if="item.subRoom">/{{ item.subRoom }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-md btn-secondary mx-0 float-right"
                @click="abort()"
              >
                {{ "Abbrechen" | translate }}
              </button>
              <button
                class="btn btn-md btn-secondary mx-0 float-left"
                @click="logOut()"
              >
                {{ "LogOut" | translate }}
              </button>
            </div>
          </div>
          <div v-if="clientState === 'showDateSelect'" key="dt">
            <b-calendar
              class="border rounded p-2"
              @selected="upDte"
              label-help=""
              selected-variant="primary"
              today-variant="info"
              :min="itemDates[0]"
              :max="itemDates[1]"
              block
              v-model="validFor"
              locale="de-DE"
            ></b-calendar>
            <button
              class="btn btn-md btn-secondary mx-0 float-left"
              @click="abort()"
            >
              {{ "Abbrechen" | translate }}
            </button>
            <button
              class="btn btn-md btn-primary mx-0 float-right"
              @click="changeClient"
            >
              {{ "Zuruck " | translate }}
            </button>
          </div>
          <div v-if="clientState === 'showShiftSelect'" key="sf">
            <div
              class="m-1 p-3"
              style="border: 1px solid #ced4da; border-radius: 0.25rem"
            >
              <div class="row m-0 p-0">
                <div
                  class="col-6 px-1"
                  v-for="(item, index) in shiftList"
                  :key="index"
                >
                  <button
                    class="btn btn-sm btn-primary btn-block"
                    @click="upShf(index + 1)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </div>
            <br />

            <button
              class="btn btn-md btn-secondary mx-0 float-left"
              @click="abort()"
            >
              {{ "Abbrechen" | translate }}
            </button>
            <button
              class="btn btn-md btn-primary mx-0 float-right"
              @click="changeDate"
            >
              {{ "Zuruck " | translate }}
            </button>
          </div>
          <div v-if="clientState === 'clientSet'" key="cs">
            <button
              class="btn btn-md btn-primary mx-0 btn-block mb-2"
              @click="changeClient()"
            >
              {{ "Neuen Kunden Auswählen" | translate }}
            </button>
            <div v-show="clientError">
              <p>
                {{ "cartError" | translate }}
              </p>
              <button class="btn btn-sm btn-secondary" @click="abort()">
                {{ "Abbrechen" | translate }}
              </button>
              <button
                class="btn btn-sm btn-primary"
                @click="changeClientConfirm()"
              >
                {{ "Bestätigen" | translate }}
              </button>
            </div>

            <div v-if="clientActive">
              <button
                class="btn btn-md btn-primary mx-0 btn-block mb-2"
                @click="changeDate()"
              >
                {{ "Neues Datum Auswählen" | translate }}
              </button>
              <div v-show="dateError">
                <p>
                  {{ "cartError" | translate }}
                </p>
                <button class="btn btn-sm btn-secondary" @click="abort()">
                  {{ "Abbrechen" | translate }}
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  @click="changeDateConfirm()"
                >
                  {{ "Bestätigen" | translate }}
                </button>
              </div>
              <button
                class="btn btn-md btn-primary mx-0 btn-block mb-2"
                @click="changeShift()"
              >
                {{ "Neue Shift Auswählen" | translate }}
              </button>
              <div v-show="shiftError">
                <p>
                  {{ "cartError" | translate }}
                </p>
                <button class="btn btn-sm btn-secondary" @click="abort()">
                  {{ "Abbrechen" | translate }}
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  @click="changeShiftConfirm()"
                >
                  {{ "Bestätigen" | translate }}
                </button>
              </div>
            </div>

            <button
              class="btn btn-md btn-secondary mx-0 btn-block"
              @click="showOrders()"
            >
              {{ "Bestellungen" | translate }}
            </button>
            <button
              class="btn btn-md btn-secondary mx-0 btn-block"
              @click="abort()"
            >
              {{ "Abbrechen" | translate }}
            </button>
            <button
              class="btn btn-md btn-info mx-0 btn-block"
              @click="logOut()"
            >
              Log Out
            </button>
          </div>
        </transition>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DataService from "@/services/DataService";

export default {
  name: "app-clientview",
  components: {},
  data() {
    return {
      initial: true,
      searchEnabled: true,
      scroll: true,
      selectedClient: { id: null, name: null, room: null, subRoom: null, info: null },
      clientUpdate: "",
      validFor: null,
      validForShift: null,
      selected: null,
      search: "",
      client: "Select Client",
      clientList: [],
      clientState: "showClientSelect",
      dateButton: false,
      shiftButton: false,
      goButton: false,
      clientError: false,
      dateError: false,
      shiftError: false,
      searching: false,
      searchError: false
    };
  },
  computed: {
    ...mapGetters(["userData", "companyData", "clientData", "total"]),
    ...mapState({
      products: (state) => state.products.all,
    }),
    clientActive() {
      if (this.clientData != null) {
        return true;
      } else {
        return false;
      }
    },
    shiftList() {
      return this.companyData.shiftNames.filter((item) => item !== "");
    },
    itemDates() {
      var pdts = this.products;
      var prodCopy = pdts.map((a) => a);
      var spdts = prodCopy.sort((a, b) => {
        return a.groupSort - b.groupSort;
      });
      var dates = (pdts = [...new Set(spdts.map(({ validFor }) => validFor))]);
      var arrStrings = dates.filter((item) => item != null);
      var arrDates = arrStrings.map((str) => new Date(str));
      var min = new Date(Math.min(...arrDates));
      var max = new Date(Math.max(...arrDates));
      return [min, max];
    },
  },
  methods: {
    ...mapActions(["getProducts", "setClient", "removeAllFromCart"]),
    showProducts() {
      var selection = {
        clientID: this.selectedClient,
        validFor: this.validFor,
        validForShift: this.validForShift,
      };
      this.setClient(selection);
      this.initial = false;
      this.searchEnabled = false;
      this.clientUpdate =
        this.selectedClient.name + " - Room: " + this.selectedClient.room + "/" + this.selectedClient.subRoom;
      this.$refs["clientModal"].hide();
      this.clientState = "clientSet";
    },
    upCid(val) {
      this.selectedClient = {
        id: val.id,
        name: val.name,
        room: this.check(val.room),
        subRoom: val.subRoom,
        info: val.info,
      };
      this.clientState = "showDateSelect";
    },
    upDte() {
      this.clientState = "showShiftSelect";
    },
    upShf(val) {
      this.validForShift = val;
      this.showProducts();
    },
    check(val) {
      if (val.indexOf(".") !== -1) {
        var y = val.split(".")[1];
        return y;
      } else {
        return val;
      }
    },
    shorten(val) {
      if (val.length > 16) {
        return val.substring(0, 16);
      } else {
        return val;
      }
    },
    showClientModal() {
      this.$refs["clientModal"].show();
    },
    searchClient() {
      this.clientList = [],
      this.searching = true;
      this.searchError = false;
      DataService.allByMatch(
        this.search,
        this.userData.token,
        this.userData.apiKey
      ).then((response) => {
        this.searching = false;
        this.clientList = response.data.lines;
      }).catch((error) => {
        if(error.response.status == 404){
          this.searching = false;
          this.searchError = true;
        }
      });
    },
    changeClient() {
      if (this.total > 0) {
        this.clientError = true;
        this.dateError = false;
        this.shiftError = false;
      } else {
        this.clientError = false;
        this.dateError = false;
        this.shiftError = false;
        this.clientList = [];
        this.search = "";
        this.dateButton = false;
        this.clientState = "showClientSelect";
        this.searchEnabled = true;
        this.selectedClient = { id: null, name: null, room: null, subRoom: null, info: null };
        this.validFor = null;
        this.validForShift = null;
      }
    },
    changeClientConfirm() {
      this.$root.$emit("remoteCheckout");
      this.clientError = false;
      this.dateError = false;
      this.shiftError = false;
      this.clientList = [];
      this.search = "";
      this.dateButton = false;
      this.clientState = "showClientSelect";
      this.searchEnabled = true;
      this.selectedClient = { id: null, name: null, room: null, subRoom: null, info: null };
      this.validFor = null;
      this.validForShift = null;
    },
    changeDate() {
      if (this.total > 0) {
        this.dateError = true;
        this.clientError = false;
        this.shiftError = false;
      } else {
        this.dateError = false;
        this.clientError = false;
        this.shiftError = false;
        this.shiftButton = false;
        this.clientState = "showDateSelect";
        var clientSelected = this.clientData;
        if (clientSelected && clientSelected != 0) {
          var bc = {
            id: clientSelected.clientID["id"],
            name: clientSelected.clientID["name"],
            room: clientSelected.clientID["room"],
            subRoom: clientSelected.clientID["subRoom"],
            info: clientSelected.clientID["info"],
          };
          this.selectedClient = bc;
        }
        this.validFor = null;
        this.validForShift = null;
      }
    },
    changeDateConfirm() {
      this.$root.$emit("remoteCheckout");
      this.dateError = false;
      this.clientError = false;
      this.shiftError = false;
      this.shiftButton = false;
      this.clientState = "showDateSelect";
      var clientSelected = this.clientData;
      if (clientSelected && clientSelected != 0) {
        var bc = {
          id: clientSelected.clientID["id"],
          name: clientSelected.clientID["name"],
          room: clientSelected.clientID["room"],
          subRoom: clientSelected.clientID["subRoom"],
          info: clientSelected.clientID["info"],
        };
        this.selectedClient = bc;
      }
      this.validFor = null;
      this.validForShift = null;
    },
    changeShift() {
      if (this.total > 0) {
        this.shiftError = true;
        this.dateError = false;
        this.clientError = false;
      } else {
        this.shiftError = false;
        this.dateError = false;
        this.clientError = false;
        this.clientState = "showShiftSelect";
        this.searchEnabled = false;
        var clientSelected = this.clientData;
        if (clientSelected && clientSelected != 0) {
          var bc = {
            id: clientSelected.clientID["id"],
            name: clientSelected.clientID["name"],
            room: clientSelected.clientID["room"],
            subRoom: clientSelected.clientID["subRoom"],
            info: clientSelected.clientID["info"],
          };
          this.selectedClient = bc;
        }
        this.validFor = clientSelected.validFor;
        this.validForShift = null;
      }
    },
    changeShiftConfirm() {
      this.$root.$emit("remoteCheckout");
      this.shiftError = false;
      this.dateError = false;
      this.clientError = false;
      this.clientState = "showShiftSelect";
      this.searchEnabled = false;
      var clientSelected = this.clientData;
      if (clientSelected && clientSelected != 0) {
        var bc = {
          id: clientSelected.clientID["id"],
          name: clientSelected.clientID["name"],
          room: clientSelected.clientID["room"],
          subRoom: clientSelected.clientID["subRoom"],
          info: clientSelected.clientID["info"],
        };
        this.selectedClient = bc;
      }
      this.validFor = clientSelected.validFor;
      this.validForShift = null;
    },
    showOrders() {
      this.$router.push({ path: "/orders" });
    },
    abort() {
      this.clientState = "clientSet";

      if (this.clientData != null) {
        this.searchEnabled = false;
        var bc = {
          id: this.clientData.clientID["id"],
          name: this.clientData.clientID["name"],
          room: this.clientData.clientID["room"],
          subRoom: this.clientData.clientID["subRoom"],
          info: this.clientData.clientID["info"],
        };
        this.selectedClient = bc;
        this.clientUpdate =
          this.selectedClient.name + " - Room: " + this.selectedClient.room + "/" + this.selectedClient.subRoom;
      } else {
        this.searchEnabled = true;
      }
      this.$refs["clientModal"].hide();
      this.clientError = false;
      this.dateError = false;
      this.shiftError = false;
    },
    logOut() {
      window.localStorage.clear();
      window.location.href = "/login/?lang=" + this.companyData.defaultLanguage;
    },
  },

  created() {
    this.getProducts({ key: this.companyData.menuIdPreOrder });
    if (this.clientData) {
      this.clientState = "clientSet";
      this.searchEnabled = false;
      var bc = {
        id: this.clientData.clientID["id"],
        name: this.clientData.clientID["name"],
        room: this.clientData.clientID["room"],
        subRoom: this.clientData.clientID["subRoom"],
        info: this.clientData.clientID["info"],
      };
      this.selectedClient = bc;
      this.validFor = this.clientData.validFor;
      this.validForShift = this.clientData.validForShift;
    } else {
      this.clientState = "showClientSelect";
      this.searchEnabled = true;
      this.selectedClient = { id: null, name: null, room: null, subRoom: null, info: null };
      this.validFor = null;
      this.validForShift = null;
    }
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.$root.$on("showCModal", () => {
      this.$refs["clientModal"].show();
    });
    this.$root.$on("selectNewClient", () => {
      this.$refs["clientModal"].show();
    });
  },
};
</script>

<style scoped>
.grtxt {
  padding-left: 5px;
  width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
